<template>
    <div class="lawfirm">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="批次信息" fixed placeholder safe-area-inset-top />
        <div class="list">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                >
                <div class="item" v-for='(item,index) in list' :key='index' @click="link(item.batchId)">
                    <div class="img">
                        <img :src="imglink" alt="">
                    </div>
                    <div class="info">
                        <div class="name overnowrap">{{item.batchId}}</div>
                        <div class="time">￥{{item.prosecutionMoney > 0 ?Number(item.prosecutionMoney).formatMoney():"0.00"}}</div>
                    </div>
                    <div class="right">{{item.count}}件<span class="symbol">&#155</span></div>
                </div>
            </van-list>
            
            <!-- <div v-else class="no-data">
                <img src="../assets/no-data.png" alt="">
            </div> -->
        </div>
    </div>
</template>

<script>
import { button, Toast ,NavBar , Tab, Tabs ,List } from 'vant';
import ShowTotal from '@/components/ShowTotal'
import ItemServiceList from '@/components/ItemServiceList'
export default {
    name:"lawfirm",
    data(){
        return{
            loading: false,
            finished: false,
            imglink:require("../../assets/lawfirm.png"),
            list:[],
            deptCode:this.$route.query.deptCode,
            feeType:this.$route.query.feeType,
            current:0
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        link(batchId){
            this.$router.push({path:'/common/person',query:{batchId:batchId,feeType:this.feeType}})
        },
        getService(){
            this.current ++
            if(this.feeType == '30060002')  this.imglink = require("../../assets/lawfirm.png")
            else if(this.feeType == '30060004')  this.imglink = require("../../assets/service.png")
            else if(this.feeType == '30060005')  this.imglink = require("../../assets/mediate.png")
            this.$get(`/finance/api/fee/listPlaintiffBatchFeeInfo`,{feeType:this.feeType,plaintiffDeptCode:this.deptCode,page:this.current,size:20}).then(res => {
                if (res.code === '200') {
                    if(this.list.length == 0){
                        this.list = res.data.records
                    }else{
                        this.list = [...this.list,...res.data.records]
                    }

                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (res.data.records.length < 20) {
                        this.finished = true;
                    }
                    // this.list = res.data.records
                    // Toast.clear();
                }
            })
        },
        onLoad() {
            setTimeout(() => {
                this.getService(this.status)
            }, 1000);
        },
    },
    mounted() {
    },
    components:{
        [Toast.name]: Toast,
        [button.name]: button,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [NavBar.name]: NavBar,
        [List.name]: List,
        ShowTotal,
        ItemServiceList
    }
}
</script>

<style lang="scss" scoped>
    .lawfirm{
        background: #F4F4F4;
        height: 100vh;
    }
</style>